import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo/logo_dark.png'
import AnimazeLogo from '../../assets/images/Animaze/animaze.png';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);


  return (
    <div>
      <footer id="footer" style={styles.footer}>
        <div style={styles.logos}>
          <img src={AnimazeLogo} alt="logo" style={styles.img} />
          <span style={styles.logoText}>+</span>
          <img src={logo} alt="logo" style={styles.image} />
        </div>
      </footer>
      {
        isVisible &&
        <Link onClick={scrollToTop} to='#' id="scroll-top"></Link>
      }

    </div>
  );
}

const styles = {
  footer: {
    backgroundImage: 'url(/assets/back2.png)',
    padding: '10px 0',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logos: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 16,
  },
    logoText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 700,
    marginLeft: 8,
    marginRight: 8,
  },
    img: {
    width: '100px',
    height: '60px',
  },
    image: {
    width: '100px',
    height: '80px',
  },
};

export default Footer;
