import React, { useState, Fragment, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import * as nearAPI from 'near-api-js';

import CardModalVIPLS from '../CardModalVIPLS';

import 'swiper/scss';
import "swiper/css/effect-fade";

import logo from '../../../assets/images/Animaze/animaze.png';
import { ReactComponent as NearType } from '../../../assets/images/icon/near_type.svg'
import { ReactComponent as RightArrow } from '../../../assets/right-arrow-svgrepo-com.svg'

import { useSelector } from 'react-redux';

const NFT = ({ nft }) => {

  const nearWallet = useSelector(state => state.near.wallet);
  const nftContract = useSelector(state => state.near.nftContract);
  const [modalShow, setModalShow] = useState(false);
  const [slideTitle, setSlideTitle] = useState(nft.TITLE);
  const [qtySold, setQtySold] = useState('-');
  const [price, setPrice] = useState(0);
  const [tokens, setTokens] = useState([]);
  const { utils } = nearAPI;

  const getTokens = async () => {
    nftContract.get_minted_quantity().then((qty) => {
      setQtySold(qty); 
      if (qty > 500) setPrice(15);
      else if (qty > 300) setPrice(10);
      else if (qty > 100) setPrice(6);
      else setPrice(0);
    });
    if (nearWallet?.isSignedIn()) {
      nftContract.nft_tokens_for_owner({account_id: nearWallet.getAccountId()}).then((t) => { setTokens(t); });
    }
  }

  useEffect(() => {
    if (nftContract) {
      getTokens();
    }
  }, [nftContract, nearWallet]);

  const handlePurchase = async () => {
    const result = await nftContract.nft_mint(
      {},
      "75000000000000",
      utils.format.parseNearAmount(price.toString()) 
    );
    getTokens();
    setModalShow(false);
  }

console.log(tokens);

  return (
    <Fragment>
      <section className="tf-section live-auctions" style={{ backgroundImage: 'url("/assets/animaze/bg.png")' }}>
      { tokens && tokens.length > 0 && tokens.map((token, ind) => (
        <div key={'nft'+ind} className="themesflat-container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="swiper-container show-shadow carousel auctions">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="sc-card-product">
                        <h3 style={{textAlign: 'center', marginBottom: 20}}>Your Pass</h3>
                          <div className="card-media">         
                            <img src={token.metadata.media} alt={token.metadata.description} />
                          </div>
                          <div className="card-title" style={{ color: '#ffffff' }}>
                            <h5><a>{token.metadata.description}</a></h5>   
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <div className="info">
                                <span>Owner</span>
                                <h6><a to="/authors-02">{token.owner_id}</a> </h6>
                              </div>
                            </div>                                                        
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        ))}
        { tokens.length == 0 &&
          <div className="themesflat-container">
          <div className="row" style={{display:'none'}}>
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="heading-live-auctions">
                <h2 className="tf-title" style={{padding:0}}>{nft.TITLE}</h2>   
                <span style={{fontSize:16}}>{2000-qtySold} of 2000 available</span>                             
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">                            
              <div className="swiper-container show-shadow carousel auctions">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="sc-card-product">
                        { 
                          qtySold != 2000 &&
                          <div className="button-place-bid" style={{zIndex: 22}}>
                              <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Get Pass</span></button>
                          </div>
                        }
                        <div className="card-media">
                          <Swiper
                            spaceBetween={10}
                            effect={"fade"}
                            modules={[EffectFade, Autoplay]}              
                            loop
                            autoplay={{
                              delay: 1,
                              disableOnInteraction: false,
                              pauseOnMouseEnter: false
                            }}
                            speed= {3000}
                            style={{borderRadius: '5px'}}
                            onSlideChangeTransitionStart={(e) => {
                              switch(e.realIndex) {
                                case 0: setSlideTitle(nft.TITLE); break;                                                                    
                              }                                                                
                            }}
                          >
                            <SwiperSlide><img src={nft.POSTER_IMAGES[0]} alt={nft.TITLE} /></SwiperSlide>                                                            
                          </Swiper>
                          { 
                            qtySold != 2000 &&
                            <div className="button-place-bid" style={{zIndex: 22}}>
                              <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Get Pass</span></button>
                            </div>
                          }
                        </div>

                        { 
                          qtySold != 2000 &&
                          <div className="button-place-bid" style={{zIndex: 22}}> 
                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Get Pass</span></button>
                          </div>
                        }
                        <div className="card-title">
                          <h5><a>{slideTitle}</a></h5>  
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar" style={{borderRadius:0}}>
                              <img src={logo} alt={nft.TITLE} />
                            </div>
                            <div className="info">
                              <span>Creator</span>
                              <h6><a to="/authors-02">{nft.CREATOR}</a> </h6>
                            </div>
                            </div>
                            <div className="price" style={{}}>                                                                        
                              <h5 style={{fontSize:40, display:'flex', alignItems: 'center', marginRight: 10}}>
                                { 
                                  qtySold == 2000 
                                  ? 'SOLD OUT'
                                  : <>
                                    {price == 0 && <span style={{fontSize: 28}}>FREE</span>}
                                    {price > 0 && <>{price} <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /> </>}
                                  </>
                                }
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>               
        } 
        </section>
        { tokens.length == 0 &&
          <CardModalVIPLS
            show={modalShow}
            onHide={() => setModalShow(false)}
            onPurchase={handlePurchase}                
            price={price}
          />
        }
    </Fragment>
  );
}


export default NFT;
