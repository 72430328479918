import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import APP from '../../constants/app';

//import logo from '../../assets/images/logo/logo_dark.png'
import AnimazeLogo from '../../assets/images/Animaze/animaze.png';
import NEAR from '../../constants/near';

const NavHeader = ({ onShow }) => {
  const nearWallet = useSelector(state => state.near.wallet);  
  // const nftContract = useSelector(state => state.near.nftContract);
  
  const [showMenu, setShowMenu] = useState(false);

  const handleWalletClick = (e) => {
    if (nearWallet?.isSignedIn()) {
        setShowMenu(!showMenu);
    } else {
        login();
    }
  }

  const login = () => {
    nearWallet?.requestSignIn(
      NEAR.NFT_CONTRACT_NAME,
      APP.TITLE
    );
  }

  const logout = () => {
    nearWallet?.signOut();
    setShowMenu(false);
  }

  // useEffect(() => {
  //   if (nftContract) {
  //     nftContract.nft_total_supply().then((qty) => setQtySold(qty));
  //   }
  // }, [nftContract, nearWallet]);
  return (
    <>
      <Navbar className="js-header" expand="lg" variant="dark" fixed='top' style={styles.nav}>
        <div style={styles.container}>
          <Container style={styles.bottomContainer}>
            <div className="justify-content-center">
              <Link to="/" rel="home">
                <div style={styles.logos}>
                  <img src={AnimazeLogo} alt="logo" style={styles.img} />
                  <span style={styles.logoText}>+</span>
                  <img src={APP.LOGO} alt="logo" style={styles.image} />
                </div>
              </Link>
            </div>
            
            <div className="justify-content-end">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`} style={styles.popover}>
                    <Popover.Body>
                      <div className="links">
                        <a className="" onClick={() => {logout()}} id="logout" style={styles.popoverText}>
                          <i className="fal fa-sign-out"></i> <span> Disconnect</span>
                        </a>
                    </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="sc-btn-top mg-r-12" id="site-header">
                  <span onClick={handleWalletClick} className="sc-button header-slider style style-1 wallet fl-button pri-1 pointer" style={nearWallet?.isSignedIn()?{border:'none'}:{}}>
                    <span>{ nearWallet?.isSignedIn() ? nearWallet.getAccountId() : 'Connect Wallet' }</span>
                  </span>
                </div>
              </OverlayTrigger>

              {/* <div className="button-place-bid" style={{ zIndex: 22, marginRight: 16 }}>
                <button onClick={() => onShow()} className="sc-button style-place-bid style bag fl-button pri-3">
                  <span>Go to Animaze Website</span>
                </button>
              </div> */}
            </div>
          </Container>
        </div>
      </Navbar>
    </>
  );
}

const styles = {
  nav: {
    backgroundImage: 'url(/assets/back2.png)',
    backgroundColor: '#030303',
  },
  navContainer: {
    height: '80px',
  },
  logos: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 12,
  },
  logoText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 700,
    marginLeft: 6,
    marginRight: 6,
  },
  img: {
    width: '80px',
    height: '60px',
  },
  image: {
    width: '80px',
    height: '80px',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'auto',
    left: 0,
    right: 0,
    width: '100%',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  qty: {
    color: '#fff',
    fontSize: '1.5rem',
    marginLeft: '1rem',
  }
};

export default NavHeader;
