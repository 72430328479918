import NearHUBLogo from '../assets/images/logo/logo_dark.png';
import AnimazeImage from '../assets/images/Animaze/art-animaze.jpg'

const APP = {
    TITLE: 'ANIMAZE Anime Film Festival',            
    LOGO: NearHUBLogo,
    NFTS: [
        {        
            URL: '/anime-film-festival',
            TITLE: 'ANIMAZE Anime Film Festival',
            DESCRIPTION: '',
            CREATOR: 'ANIMAZE & NearHUB',
            POSTER_IMAGES: [AnimazeImage],
            LAUNCH: new Date(Date.UTC('2022', '03', '20', '16', '20', '0')),
            PRICES: [
                {
                    limit: 100,
                    price: 0
                },
                {
                    limit: 300,
                    price: 6
                },
                {
                    limit: 500,
                    price: 10
                },
                {
                    limit: 'none',
                    price: 15
                }                
            ]
        }
    ]
};

export default APP;