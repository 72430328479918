import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import Countdown from "react-countdown";
import * as nearAPI from 'near-api-js';

import GridSystem from './GridSystem';
import Card from 'react-bootstrap/Card';

import CardModal from '../CardModal';

import 'swiper/scss';
import "swiper/css/effect-fade";

import APP from '../../../constants/app';

import AnimazeLogo from '../../../assets/images/Animaze/animaze.png';
import AnimazeImage from '../../../assets/images/Animaze/art-animaze.jpg'

import { useSelector } from 'react-redux';

const Main = () => {
  const [data, setData] = useState(APP.NFTS.map(NFT => {
    return {
        img: NFT.POSTER_IMAGES,
        title: NFT.TITLE,
        nameAuthor: NFT.CREATOR,
        launch: NFT.LAUNCH,
        selected: 1,
        prevSelected: 1,
        url: NFT.URL,
        available: 2000,
        sold: '-'
      }
    })
  );

  const [now] = useState(Date.now());
  const [modalShow, setModalShow] = useState(false);

  const nearWallet = useSelector(state => state.near.wallet);
  const nftContractWL   = useSelector(state => state.near.nftContract);    

  useEffect(() => {
      setInterval(() => {
          const newData = data.map(ele => {
              if(typeof ele.selected == 'number') {
                  ele.prevSelected = ele.selected;
                  ele.selected++;
                  if (ele.selected > ele.img.length) ele.selected = 1;
              } else {
                  ele.prevSelected = [...ele.selected];
                  ele.selected = ele.selected.map((ele2, ind2) => Math.floor(Math.random() * ele.img[ind2].length) + 1);
              }
              return ele;
          });

          setData(newData);
      }, 5000)
  }, []);

  useEffect(() => {
      if (nftContractWL) {
          nftContractWL.get_user_minted_quantity().then((qty) => {
              data[1].sold = qty;
              setData(data);                
          });            
          nftContractWL.get_free_minted_quantity().then((qty) => {
              data[0].sold = qty;
              setData(data);                
          });            
      }
  }, [nftContractWL, nearWallet]);

  return (
    <Fragment>
      <section className="tf-section live-auctions" style={styles.container}>
        <div style={{maxWidth:600, margin: '0 auto'}}>
          <GridSystem colCount={1} md={12} title="Upcoming Event">
            {
              data.map((item, index) => (                                       
                <div key={'item'+index} className="swiper-container show-shadow">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="slider-item">
                        <div className="sc-card-product">
                          <div className="card-media">
                            <Link to={item.url}>
                              {
                                item.img.map((img, ind) => {
                                  if (typeof img == 'string') {
                                    return <img key={'img'+index+ind} src={(item.selected == (ind+1) || item.prevSelected == (ind+1) || ind == 0) ? img : AnimazeImage} alt={item.title} className={item.selected == (ind+1) ? 'img selected' : 'img'} />
                                  } else {
                                      return (
                                        img.map((img2, ind2) => 
                                          <img 
                                            key={'img'+index+ind+ind2} 
                                            src={(item.selected[ind] == (ind2+1) || item.prevSelected[ind] == (ind2+1) || ind == 0) ? img2 : AnimazeImage} 
                                            alt={item.title} 
                                            className={item.selected[ind] == (ind2+1) ? 'img selected' : 'img'} 
                                          />
                                        )
                                      );
                                  }                                                                        
                                })
                              }                                                                                                                                    
                            </Link>
                          </div>
                          <div className="card-title" style={{ color: '#fff' }}>
                            <h5><Link to={item.url}>{item.title}</Link></h5>                                                                    
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <div className="avatar" style={{borderRadius:0}}>
                                <img src={AnimazeLogo} alt="Animaze image" />
                              </div>
                              <div className="info">
                                <span>Creator</span>
                                <h6>{item.nameAuthor}</h6>
                              </div>
                            </div>       
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            } 
          </GridSystem>                        
        </div>
      </section>
      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Fragment>
  );
}

const styles = {
  container: {
    backgroundImage: 'url("/assets/animaze/bg.png")',
  },
};


export default Main;
