import NFTPage from "./NFTPage";
import Home from "./Home";

import AnimazeImage from '../assets/images/Animaze/art-animaze.jpg'

import APP from '../constants/app';

const routes = [
  {
    path: '/',
    component: <Home />
  },
  ...APP.NFTS.map(nft => {
    return {
      path: nft.URL,
      component: <NFTPage nft={nft} />,
    }
  })
  /*
  {
    path: '/anime-film-festival',
    component: <NFTPage />
  },
  */
]

export default routes;
