import React from 'react';

import NavHeader from '../components/header/NavHeader';
import Footer from '../components/footer/Footer';
import NFT from '../components/layouts/home/NFT';

const NFTPage = ({ nft }) => {
  return (
    <div className='home-5'>
      <NavHeader />
      <NFT nft={nft} />            
      <Footer />
    </div>
  );
}

export default NFTPage;
